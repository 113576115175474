<template>
  <div>
    <div id="quar-goal">
      <button @click="toggleGoals()">
        <fa-icon v-if="qgoalsOpen" :icon="['fas', 'caret-right']"></fa-icon>
        <fa-icon v-else :icon="['fas', 'caret-left']"></fa-icon>
      </button>
      <div id="inner"></div>
    </div>
    <table>
      <tr class="bordered">
        <td>Projektname</td>
        <td>{{ project.projectname }}</td>
      </tr>
      <tr class="bordered">
        <td>Status</td>
        <td v-if="project.status === 'inProgress'">
          In Arbeit
          <fa-icon
            class="stat-circle"
            style="color: #ffd500"
            v-if="project.status === 'inProgress'"
            :icon="['fas', 'circle']"
          ></fa-icon>
        </td>
        <td v-if="project.status === 'done'">
          Abgeschlosen
          <fa-icon
            class="stat-circle"
            style="color: #00e600"
            v-if="project.status === 'done'"
            :icon="['fas', 'circle']"
          ></fa-icon>
        </td>
        <td v-if="project.status === 'canceled'">
          Abgebrochen
          <fa-icon
            class="stat-circle"
            style="color: #ff0000"
            v-if="project.status === 'canceled'"
            :icon="['fas', 'circle']"
          ></fa-icon>
        </td>
      </tr>
      <tr class="bordered">
        <td>Projektende</td>
        <td>{{ project.date }}</td>
      </tr>
      <tr class="bordered">
        <td>Projektende (ComLine)</td>
        <td>{{ project.dateCom }}</td>
      </tr>
      <tr>
        <td>Beschreibung</td>
        <td></td>
      </tr>
    </table>
    <div id="description-field"></div>

    <div v-if="files.length !== 0" id="appendix-label">Anhänge</div>
    <ul v-if="files.length !== 0" id="appendix">
      <li v-for="(element, index) in files" :key="index">
        <a target="_blank" :href="element.url">{{ element.name }}</a>
      </li>
    </ul>
    <button @click="editProject">Bearbeiten</button>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/de";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../plugins/firebase";

export default {
  name: "ProjectOverview",
  props: {
    data: Object,
    token: String,
  },
  data() {
    return {
      project: {
        date: null,
        dateCom: null,
        projectname: "",
        status: null,
        description: null,
        goalnote: "",
        appendix: false,
      },
      files: [],
      qgoalsOpen: true,
    };
  },
  methods: {
    loadData() {
      this.project = this.data["doc"];
      document.getElementById("description-field").innerHTML =
        this.project.description;
      if (document.getElementById("description-field").firstChild) {
        document.getElementById(
          "description-field"
        ).firstChild.style.marginTop = 0;
      }
      if (!this.project.goalnote || this.project.goalnote === "")
        this.toggleGoals();
      else document.getElementById("inner").innerHTML = this.project.goalnote;
      if (this.project.appendix) this.loadAppendix();
    },
    loadAppendix() {
      const stoRef = ref(storage, this.token);
      listAll(stoRef)
        .then((res) => {
          res.items.forEach((file) => {
            if (file.name.includes(this.data["key"])) {
              this.renameAndPush(file);
            }
          });
        })
        .catch((err) => {
          console.log("firebase storage error", err);
        });
    },
    renameAndPush(file) {
      const name = file.name.replaceAll("-" + this.data["key"], "");
      const stoRef = ref(storage, this.token + "/" + file.name);
      getDownloadURL(stoRef).then((url) => {
        this.files.push({
          name: name,
          url: url,
        });
      });
    },
    editProject() {
      this.$emit("editThisProject", this.data);
    },
    toggleGoals() {
      const modal = document.getElementById("quar-goal");

      if (this.qgoalsOpen) {
        modal.style.width = "27px";
        this.qgoalsOpen = false;
      } else {
        modal.style.width = "70%";
        this.qgoalsOpen = true;
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
input,
select {
  width: 100%;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0.375rem 0.75rem;
  padding-left: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}
button {
  margin-top: 20px;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid rgb(64, 137, 201);
  background-color: rgb(64, 137, 201);
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
.red {
  background-color: #cb444a !important;
  border-color: #cb444a !important;
}
div {
  margin-top: 15px;
  max-width: 100%;
  position: relative;
}
.datePicker {
  width: 100%;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.working {
  background-color: lightgray;
  border: none;
  padding: 6px 35px;
  cursor: default;
}
.done {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 6px 35px;
  cursor: default;
}
.error {
  border-color: red !important;
}
.errormessage {
  color: red;
}
.cashback {
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.cashbackReady {
  border: 1px solid #bee5eb;
  border-radius: 5px;
  color: #0c5460;
  background-color: #d1ecf1;
}
.warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}
.customModal {
  position: fixed;
  top: 100px;
  left: 0;
  background-color: #e8f1f9;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100vw;
  max-width: 100vw;
  box-shadow: #9e9e9e 0.25px 1px 2px;
  transition: opacity 5s;
}
.customModal div,
.close {
  margin: 15px;
}
.close {
  background-color: #e8f1f9;
  border: none;
  color: #5a6268;
  cursor: pointer;
  position: absolute;
  margin-left: 350px;
}
.close:hover {
  color: rgb(187, 187, 187);
}
#arrow {
  position: absolute;
  right: 10px;
  top: 34px;
  color: #9f9f9f;
}
.stat-circle {
  height: 13px;
  margin-left: 10px;
}
select:nth-of-type(1) {
  padding-left: 32px;
}
table {
  border-collapse: collapse;
  margin: 0 5px;
}
table tr td {
  padding: 13px 10px;
  font-size: 14px;
}
table tr:first-of-type td {
  padding-top: 2px;
}
table tr td:first-child,
#appendix-label {
  font-weight: 600;
}
table tr td:last-child {
  width: 100%;
}
tr td:not(tr:last-of-type td) {
  border-bottom: 1px solid #ced4da;
}
#description-field {
  margin: 0 15px;
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 15px;
}
#quar-goal {
  position: absolute;
  right: -16px;
  background-color: white;
  border: 1px solid #ced4da;
  top: -16px;
  margin-top: 0;
  border-radius: 5px;
  height: 171px;
  width: 70%;
  transition: width 0.5s;
  overflow: hidden;
}
#quar-goal button {
  position: absolute;
  z-index: 99;
  top: -20px;
  left: -1px;
  height: 100%;
}
#quar-goal button:hover {
  background-color: #2d6ba1;
  border-color: #2d6ba1;
}
#quar-goal #inner {
  width: calc(100% - 69px);
  height: 112px;
  padding: 0px 20px 20px 49px;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: clip;
}
#appendix,
#appendix-label {
  font-size: 14px;
  margin-left: 16px;
  margin-bottom: 15px;
}
#appendix {
  margin: 0 18px;
}
#appendix li:not(#appendix li:first-child) {
  margin-top: 15px;
}
#appendix li a {
  text-decoration: none;
  color: #5c5c5c;
}
#appendix li a:hover {
  color: #4089c9;
  cursor: pointer;
}
</style>

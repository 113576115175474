<template>
  <div>
    <div>
      <ul id="project-list">
        <li id="list-head">Ihre Projekte</li>
        <li id="toolbar" v-if="this.ogList.length > 1">
          <button :class="toolInProgress" @click="toggleToolbar('inProgress')">
            <fa-icon
              class="tool-inProgress"
              :icon="['fas', 'circle']"
            ></fa-icon>
          </button>
          <button :class="toolDone" @click="toggleToolbar('done')">
            <fa-icon class="tool-done" :icon="['fas', 'circle']"></fa-icon>
          </button>
          <button :class="toolCanceled" @click="toggleToolbar('canceled')">
            <fa-icon class="tool-canceled" :icon="['fas', 'circle']"></fa-icon>
          </button>
          <button :class="toolSearch" @click="toggleToolbar('search')">
            <fa-icon class="search-tool" :icon="['fas', 'search']"></fa-icon>
          </button>
        </li>
        <li v-if="showSearch" id="searchbar">
          <input
            id="small-searchbar"
            type="text"
            v-model="searchFor"
            @keyup="searchForThis"
          />
        </li>
        <div id="inner-frame">
          <li
            v-for="(element, index) in data"
            :key="index"
            @click="loadField(element)"
            :class="element['doc'].status"
          >
            <div
              :class="
                'state-indicator state-indicator-' + element['doc'].status
              "
            ></div>
            {{ element["doc"].projectname }}
          </li>
        </div>
        <li id="new-button" @click="loadField('new')">Erstellen +</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { firestore } from "../plugins/firebase";
import { collection, getDocs } from "firebase/firestore";

export default {
  name: "ListOfFeedbacks",
  components: {},
  props: {
    token: String,
  },
  data() {
    return {
      loading: true,
      data: [],
      ogList: [],
      toolInProgress: "",
      toolDone: "",
      toolCanceled: "",
      toolSearch: "",
      showSearch: false,
      searchFor: "",
    };
  },
  methods: {
    async getData() {
      if (this.token && this.token !== "") {
        this.data = [];
        const querySnapshot = await getDocs(collection(firestore, this.token));
        querySnapshot.forEach((doc) => {
          this.data.push({ doc: doc.data(), key: doc.id });
          this.ogList.push({ doc: doc.data(), key: doc.id });
          this.loading = false;
        });
      } else {
        this.data = [];
      }
    },
    toggleToolbar(button) {
      const theAffected = document.getElementsByClassName(button);
      let listItems = [];
      for (let i = 0; i < theAffected.length; i++) {
        listItems.push(theAffected[i]);
      }
      switch (button) {
        case "inProgress":
          if (this.toolInProgress === "") {
            this.toolInProgress = "deactivated";
            listItems.forEach((element) => {
              element.style.display = "none";
            });
          } else {
            this.toolInProgress = "";
            listItems.forEach((element) => {
              element.style.display = "block";
            });
          }
          break;
        case "done":
          if (this.toolDone === "") {
            this.toolDone = "deactivated";
            listItems.forEach((element) => {
              element.style.display = "none";
            });
          } else {
            this.toolDone = "";
            listItems.forEach((element) => {
              element.style.display = "block";
            });
          }
          break;
        case "canceled":
          if (this.toolCanceled === "") {
            this.toolCanceled = "deactivated";
            listItems.forEach((element) => {
              element.style.display = "none";
            });
          } else {
            this.toolCanceled = "";
            listItems.forEach((element) => {
              element.style.display = "block";
            });
          }
          break;
        case "search":
          if (this.showSearch) {
            this.showSearch = false;
            this.toolSearch = "";
            this.searchFor = "";
            this.searchForThis();
          } else {
            this.showSearch = true;
            this.toolSearch = "deactivated";
          }
          break;
        default:
          break;
      }
    },
    searchForThis() {
      this.data = [];
      this.ogList.forEach((element) => {
        if (
          element.doc.projectname
            .toLowerCase()
            .includes(this.searchFor.toLowerCase())
        ) {
          this.data.push(element);
        }
      });
    },
    loadField(data) {
      this.$emit("open-project", data);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
#project-list {
  list-style: none;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin: 0;
  padding: 0;
  width: 200px;
}
#project-list #list-head {
  font-weight: 600;
  padding: 15px;
  border-bottom: 1px solid #ced4da;
  position: relative;
  text-align: center;
  margin: 0;
}
#project-list li:not(#new-button, #list-head) {
  padding: 10px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: solid 1px #ced4da;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#project-list
  li:hover:not(
    #project-list #list-head:hover,
    #project-list #new-button:hover
  ) {
  color: #3275b0;
  cursor: pointer;
}
#new-button {
  color: #3275b0;
  font-weight: 600;
  padding: 15px;
  margin: 0;
  border-top: 1px solid #ced4da;
  position: relative;
  text-align: center;
  margin: 0;
  border-bottom: none;
}
#new-button:hover {
  color: white;
  background-color: #4089c9;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
}
.state-indicator {
  height: 1.2em;
  width: 5px;
  border-radius: 5px;
  background-color: grey;
  display: inline-block;
  position: relative;
  top: 3px;
  left: -5px;
}
.state-indicator-inProgress {
  background-color: #ffd500;
}
.state-indicator-done {
  background-color: #00e600;
}
.state-indicator-canceled {
  background-color: #ff0000;
}
#inner-frame {
  max-height: 447px;
  overflow-x: hidden;
  position: relative;
  bottom: -1px;
}

#toolbar,
#searchbar {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  width: 100% !important;
}
#toolbar button {
  width: 25%;
  height: 33px;
  background-color: white;
  border: solid 1px #ced4da;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(206, 212, 218);
  border-radius: 0px 0 5px 5px;
  border-top: none;
}
#toolbar button:hover {
  border-color: #4089c9;
  background-color: #4089c9;
  height: 32px;
}
#toolbar button:last-of-type:hover svg {
  color: #fff;
}
#toolbar .deactivated {
  opacity: 0.5;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
#toolbar .tool-inProgress {
  color: #ffd500;
}
#toolbar .tool-done {
  color: #00e600;
}
#toolbar .tool-canceled {
  color: #ff0000;
}
.search-tool {
  color: #9f9f9f;
}
#searchbar input {
  height: 33px;
  width: 150px;
  padding: 2px 15px;
  border: 1px solid #ced4da;
  border-radius: 0 0 5px 5px;
  border-top: 0;
}
</style>

<template>
  <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; margin-top: -85px; position: absolute;">
    <div class="loginBox">
      <div class="d-flex justify-content-center align-items-center">
        <div id="logoBox">
        <Logo :fill="'#000000'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo> <span id="logotext">Adobe Kickback</span>
        </div>
      </div>
      <div v-if="error" class="d-flex alert alert-danger error">{{error}}</div>
      <form action="#" @submit.prevent="submit">
        <div class="form-group">
          <div>
            <label>E-Mail-Adresse</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><fa-icon :icon="['fas', 'envelope']" /></span>
              </b-input-group-prepend>
              <b-form-input                     
                id="email"
                type="email"
                class="form-control"
                name="email"
                value
                required
                autofocus
                v-model="form.email">
              </b-form-input>
            </b-input-group>
          </div>
        </div>
        <div>
          <div>
            <label>Passwort</label>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"><fa-icon :icon="['fas', 'key']" /></span>
              </b-input-group-prepend>
              <b-form-input                     
                id="password"
                type="password"
                class="form-control"
                name="password"
                required
                v-model="form.password">
              </b-form-input>
            </b-input-group>
          </div>
        </div>

        <div class="form-group mt-4">
          <button :disabled="form.email === '' || form.password === ''" type="submit" class="btn btn-primary w-100">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { auth } from '../plugins/firebase';
import { signInWithEmailAndPassword, signInWithCustomToken } from "firebase/auth"

import Logo from '../assets/Logo'

export default {
  name: 'LoginScreen',
  components:{
    Logo
  },
  computed: {
  ...mapGetters({
    user: "user"
  })
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
     submit() {
      signInWithEmailAndPassword(auth, this.form.email, this.form.password)
      .catch(err => {
        if (err.code === 'auth/wrong-password') this.error = 'Das Passwort ist ungültig.'
        else if (err.code === 'auth/user-not-found') this.error = 'Es existiert kein Benutzerdatensatz, der dieser Kennung entspricht.'
        else if (err.code === 'auth/user-disabled') this.error = 'Das Benutzerkonto wurde von einem Administrator deaktiviert.'
        else this.error = err.message
      });
    },
    checkLogState(){
      if (this.user.loggedIn) {
        this.$router.replace({ name: "home" });
      }
    }
  },
  mounted(){
    this.checkLogState()
    let token = this.$route.params.token || 0
    signInWithCustomToken(auth, token)
    .then(()=>{
      this.$router.push({name: 'home'})
    })
    .catch(err=>{console.error(err)})
  }
};
</script>

<style scoped>
  .loginBox {
    width: 400px; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 50px;
    border: 1px solid #dedede;
    border-radius: 0.35rem;
    border-top: 5px solid #4089c9; 
  }
  .input-group-text {
  width: 48px;
  border-right: none !important;
  background-color: #ffffff;
  }
  #email, #password {
    border-left: none; 
  }
  #logoBox{
    display: flex; 
    align-content: center;
    align-items: center;
    padding-bottom: 20px; 
  }
  #logotext {
    font-size: 30px;
  }
</style>
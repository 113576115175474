<template>
  <div id="frame">
    <div id="chlo-select">
      <costumer-select style="width: 100%" :changeLog="true" v-on:load-costumer="loadLogs" :key="counter" />
      <button type="button" @click="counter++; loadLogs(0)">Filter aufheben</button>
    </div>
    <table>
      <tr>
        <th>Datum</th>
        <th>Nutzer</th>
        <th>Eintrag</th>
      </tr>
      <tr v-for="(element, index) in logs" :key="index">
        <td>
          {{ element.date }}
        </td>
        <td>{{ element.user }}</td>
        <td>{{ element.info }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { firestore } from "../plugins/firebase";
import { collection, getDocs } from "firebase/firestore";
import CostumerSelect from "./CostumerSelect.vue";

export default {
  name: "ChangeLog",
  data() {
    return {
      logs: [],
      collection: 0,
      counter: 0,
    };
  },
  components: {
    CostumerSelect,
  },
  methods: {
    async loadLogs(filter) {
      this.logs = [];
      const querySnapshot = await getDocs(collection(firestore, "logs"));
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const info =
          "Das Projekt " +
          data.project +
          " in " +
          data.collection +
          " wurde " +
          data.entryType +
          ".";
        if (filter === 0 || filter === data.collection) {
          this.logs.push({
            date: this.createReadableDate(data.timestamp),
            timestamp: data.timestamp,
            info: info,
            user: data.user,
            coll: data.collection,
          });
        }
      });
      this.sortLogs();
    },
    createReadableDate(date) {
      let day = date.toDate().getDate();
      let month = date.toDate().getMonth() + 1;
      const year = date.toDate().getFullYear();
      let hour = date.toDate().getHours();
      let min = date.toDate().getMinutes();
      if (day < 10) day = "0" + String(day);
      if (month < 10) month = "0" + String(month);
      if (hour < 10) hour = "0" + String(hour);
      if (min < 10) min = "0" + String(min);

      const reDate =
        day + "." + month + "." + year + " - " + hour + ":" + min + " Uhr";
      return reDate;
    },
    sortLogs() {
      this.logs.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return b.timestamp.seconds - a.timestamp.seconds;
      });
    },
  },
  beforeMount() {
    this.loadLogs(0);
  },
};
</script>

<style scoped>
#frame {
  margin: auto;
  width: 100%;
  padding: 0 160px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: clip;
}
table {
  border-radius: 5px;
  border-collapse: collapse;
  width: 100%;
  margin-top: 25px;
  margin: 25px 10px 150px;
}
tr:first-of-type,
th {
  position: sticky;
  top: 0px;
  z-index: 1;
}
th {
  background-color: white;
}
tr:nth-child(2) {
  height: 50px;
}
tr:nth-of-type(2) td {
  margin-top: 20px;
}
td,
th {
  padding: 15px 30px;
  height: fit-content;
}
tr:nth-of-type(even) {
  background-color: #e8f1f9a4;
}
#chlo-select button {
  padding: 5px 10px;
  border: 1px solid rgb(64, 137, 201);
  background-color: rgb(64, 137, 201);
  color: white;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 11px;
  min-width: 120px;
}
#chlo-select button:hover {
  cursor: pointer;
  background-color: #2d6ba1;
  border-color: #2d6ba1;
}
#chlo-select {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 2px;
}
</style>
import Vue from 'vue';
import Login from '../components/Login';
import Home from '../components/Home';
import Settings from '../components/Settings';
import ChangeLog from "../components/ChangeLog";
import Router from 'vue-router';
import store from '../store';
import "firebase/auth";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { 
            path: '*',
            redirect: '/login' 
          },
          { 
            path: '/', 
            redirect: '/login' 
          },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/token/:token',
            name: 'token',
            component: Login,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        },
        {
            path: '/logs',
            name: 'logs',
            component: ChangeLog,
            meta: {
                requiresAuth: true,
                requiresAdminRole: true
            }
        }
]
});

router.beforeEach(async (to, from, next) => {
    console.log(store.getters.user?.data?.admin)
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresAdminRole = to.matched.some(record => record.meta.requiresAdminRole)
    const currentUser = store.getters.user.loggedIn
    const PermissionStatus = store.getters.user.loggedIn ? store.getters.user.data.admin : false

    if (to.name === 'login' && currentUser) next({ name: 'home' })
    else if (requiresAdminRole && PermissionStatus && requiresAuth && currentUser) next()
    else if (requiresAdminRole && !PermissionStatus && requiresAuth && currentUser) next({name: 'login'})
    else if (requiresAuth && currentUser) next()
    else if (requiresAuth && !currentUser) next({ name: 'login' })
    else next()

})

export default router
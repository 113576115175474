<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary mb-4">
      <div class="container">
      <router-link class="navbar-brand d-flex" to="/">
        <Logo :fill="'#fff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
        Adobe CheckBack Adminpanel
      </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-3">
            <template v-if="this.user.loggedIn === true">
              <b-nav-item>
                <router-link class="nav-light" to="/home">Feedbacks</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link class="nav-light" to="/settings">Nutzer</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link class="nav-light" to="/logs">Änderungen</router-link>
              </b-nav-item>
            </template>
          </ul>
        </div>  
        <div>
          <template v-if="this.user.loggedIn === true">
            <a class="nav-link nav-light" @click.prevent="signOutMe"><fa-icon :icon="['fas', 'sign-out']" /></a>
          </template>
        </div>
      </div>
    </nav>
     <!-- <CheckUserName/> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { auth } from '../plugins/firebase';
import { signOut } from "firebase/auth"
import Logo from '../assets/Logo'
import store from '../store'

export default {
  name: 'NavbarThing',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data(){
    return{
      token: null
    }
  },
  components: {
    Logo,
  },
  methods: {
    async signOutMe() {
      await store.dispatch("fetchUser", null)
      signOut(auth)
      .then(() => {
        this.$user = false
        this.$router.replace({
          name: "login"
        });
      });
    },
  },
}
</script>
<style>
.navbar { 
  background-color:rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
a.nav-link,
li a {
  cursor: pointer;
  color:rgb(87, 87, 87);
}
a.nav-link:hover,
li a:hover {
  color:rgb(196, 196, 196);
}
.nav-light {
  color: #fff !important;
}
.nav-light:hover {
  color: rgba(255, 255, 255, 0.655) !important;
}
</style>
<template v-if="this.user.loggedIn">
  <div>
    <b-container>
      <div class="m-3">
        <b-alert :variant="messageState" show v-if="message">{{message}}</b-alert>
        <b-card no-body>
          <b-card-header header-tag="nav" >
            <b-nav card-header tabs>
              <b-nav-item ref="create"  @click="clicked('create')">Administrator anlegen</b-nav-item>
              <b-nav-item ref="edit"  @click="clicked('edit')">Administratoren verwalten</b-nav-item>
            </b-nav>
          </b-card-header>
          
          <b-card-body class="text-center" v-if="localNav === 'create'">
            <b-card-title>Nutzer anlegen</b-card-title>
            <b-form-group>
              <b-form-input
                id="email"
                v-model="form.createUser"
                type="email"
                placeholder="E-Mail-Adresse"
                required
                class="mb-1"
              ></b-form-input>
              <b-button variant="primary" @click="saveUser()" :disabled="savingUser">
                Speichern <b-spinner style="width: 1rem; height: 1rem;" label="small Spinner" v-if="savingUser"></b-spinner>
              </b-button>
            </b-form-group>
          </b-card-body>
          
          <b-card-body class="text-center" v-if="localNav === 'edit'">
            <b-card-title>Nutzer verwalten</b-card-title>
            <b-spinner label="Spinning" v-if="!Boolean(users.length)"></b-spinner>
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="user in users" :key="user.uid">
                <span class="email text-left">{{user.email}}</span>
                <div class="d-flex justify-content-between align-items-center">
                  <div><fa-icon class="clickable" :icon="['fas', 'trash']" @click="showModalDelete(user)" v-b-popover.hover.top="'Nutzer löschen.'"/></div>
                </div>
              </b-list-group-item>
            </b-list-group>

          </b-card-body>
        </b-card>
      </div>
      <b-modal ref="delete-modal" hide-footer title="Nutzer Löschen">
        <p class="my-4">Möchtest du den Nutzer mit der E-Mail-Adresse: <strong>{{userDelete.email}}</strong> löschen?</p>
        <b-button class="mt-3" variant="outline-danger" block @click="$refs['delete-modal'].hide()">Nein</b-button>
        <b-button class="mt-2" variant="outline-warning" block @click="deleteUser(userDelete)">Ja</b-button>
      </b-modal>
    </b-container>  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions"

export default {
  name: 'IncomingGoods',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data(){
    return{
      data: undefined,
      message: null,
      messageState: null,
      localNav: 'create',
      users: [],
      userDelete: {},
      userAdmin: {},
      savingUser: false,
      form:{          
        createUser:''
      }
    }
  },
  methods: {
    clicked(data){
      this.localNav = data
      this.$refs.edit.querySelector('a').classList.remove('active')
      this.$refs.create.querySelector('a').classList.remove('active')
      this.$refs[data].querySelector('a').classList.add('active')
      if (data === 'edit'){
        this.getUsers();
      }
    },
    saveUser(){
      if(this.form.createUser){
        this.savingUser = true
        const $this = this
        var addMessage = httpsCallable(functions, 'createUser');
        
        addMessage({ email: this.form.createUser })
          .then(() => {
            $this.setMessage('Nutzer wurde erstellt und erhält in kürze eine E-Mail.', 'success')
            $this.form.createUser= ''
            $this.savingUser = false
          })
          .catch(function(error) {
            console.log(error)
            $this.setMessage('Es ist ein Fehler aufgetreten.', 'error')
          });
      }else{
        this.setMessage('Bitte gib eine E-Mail-Adresse ein.', 'error')
      }
    },
    getUsers(){
      var getUsers = httpsCallable(functions, 'getUsers');
      getUsers()
      .then((users)=>{
        this.users = users.data.users
      })
      .catch(err=>{console.log(err)})
    },
    checkAdmin(state){
      if(!state){
        return false
      } else if(state.admin === true){
        return true
      } else {
        return false
      }
    },
    deleteUser(data){
       this.$refs['delete-modal'].hide()
      const sendData = {
        uid: data.uid
      }
       let deleteUser = httpsCallable(functions, 'deleteUser');
      deleteUser(sendData)
      .then((res)=>{
        console.log(res)
        this.getUsers()
      })
      .catch(err=>{console.log(err)})
      this.$refs['delete-modal'].hide()
      console.log("delete", data)
      this.userDelete = ""
    },
    showModalDelete(data) {
      this.$refs['delete-modal'].show()
      this.userDelete = data
    },
    setMessage(message, state){
      if(state === 'success'){
        this.message = message
        this.messageState = 'success'
        setTimeout(() =>{
          this.message = ''
        }, 50000)
      } else if ( state === 'error'){
        this.message = message
        this.messageState = 'danger'
        setTimeout(() =>{
          this.message = ''
        }, 50000)
      }
    },
  },
  async mounted(){

  } 
}
</script>

<style scoped>
  .clickable:hover {
    cursor: pointer;
    color: rgb(163, 163, 163);
  }
  .email{
    min-width: 270px;
  }
</style>

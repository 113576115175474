<template>
  <div style=" height: calc(100vh - 60px);overflow-y: auto" id="mainView">
    <div id="frame-select">
      <costumer-select :changeLog="false" v-on:load-costumer="setCollection" />
    </div>
    <div id="frame">
      <list-of-feedbacks
        v-if="activeToken !== ''"
        v-bind:token="activeToken"
        v-on:open-project="openProject"
        :key="naviKey"
      />
      <div class="content">
        <Apply
          v-if="currentProject === 'new'"
          @reload-navi="naviKey += 1"
          v-bind:projectToEdit="projectToEdit"
          v-bind:token="activeToken"
          :key="mainKey"
        />
        <project-Overview
          v-else
          v-bind:data="currentProject"
          v-bind:token="activeToken"
          :key="currentProject['doc'].projectname"
          @editThisProject="openProjectEditor"
        />
        <!-- selected-project -->
      </div>
    </div>
  </div>
</template>

<script>
import Apply from "./Apply";
import ListOfFeedbacks from "./ListOfFeedbacks.vue";
import ProjectOverview from "./ProjectOverview.vue";
import CostumerSelect from "./CostumerSelect.vue";

export default {
  name: "HomeScreen",
  data() {
    return {
      active: true,
      first: "active",
      seccond: "inactive",
      currentProject: "new",
      naviKey: 1,
      mainKey: 1,
      projectToEdit: null,
      activeToken: "",
    };
  },
  components: {
    Apply,
    ListOfFeedbacks,
    ProjectOverview,
    CostumerSelect,
  },
  methods: {
    openProject(data) {
      this.currentProject = data;
      this.projectToEdit = null;
      if (data === "new") this.mainKey += 1
    },
    openProjectEditor(project) {
      console.log(project)
      this.currentProject = "new";
      this.projectToEdit = project;
      this.mainKey += 1;
    },
    setCollection(token) {
      this.activeToken = token;
      this.currentProject = "new";
      this.naviKey += 1;
    },
  },
};
</script>


<style scoped>
#frame {
  padding: 0;
  display: flex;
  width: calc(100% - 100px);
  max-width: 1140px;
  margin: auto;
  margin-bottom: 150px;
  padding: 0px 50px;
}
#frame-select {
  padding: 0;
  width: clac(100% - 100px);
  max-width: 1140px;
  margin: auto;
  padding: 2px 50px 25px  50px ;
}
.head {
  margin: 0;
  width: 100%;
}
.content {
  padding: 0 15px 15px 15px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  width: 100%;
  margin-left: 25px;
}
.navFrame {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}
.navFrame div {
  padding: 10px;
  background-color: #f5f5f5;
  margin-right: 4px;
  margin-bottom: -1px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.navFrame div.active {
  background-color: white;
  border: 1px solid #ced4da;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 0;
}
.navFrame div a:hover {
  cursor: pointer;
}
.navFrame div.inactive a {
  color: #4089c9;
}
.navFrame div.inactive a:hover {
  color: #23527c;
}
.navFrame div.active a:hover {
  cursor: default;
}
a {
  text-decoration: none;
}
</style>

<template>
  <div id="little-frame">
    <select name="costumer-select" id="costumer-select" v-model="activeToken">
      <option
        v-for="element in cosList"
        :key="element.costumerNr"
        :value="element.costumerNr"
      >
        {{ element.company }}
      </option>
    </select>
    <fa-icon id="ze-other-arrow" :icon="['fas', 'caret-down']"></fa-icon>
    <input
      type="text"
      id="search-bar"
      placeholder=""
      @keyup.enter="selectSearch"
      v-model="search"
    />
    <div id="no-search-info" v-if="noSearch">
      Keine Einträge zu der Suche gefunden
    </div>
    <fa-icon
      v-if="search"
      @click="clearSearch"
      id="delete-search"
      :icon="['fas', 'times']"
    ></fa-icon>
    <fa-icon id="magnifying-glass" :icon="['fas', 'search']"></fa-icon>
    <button id="search-button" @click="selectSearch">Suchen</button>
  </div>
</template>

<script>
import { firestore } from "../plugins/firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

export default {
  name: "CostumerSelect",
  props: {
    changeLog: Boolean,
  },
  data() {
    return {
      cosList: [],
      activeToken: "",
      search: "",
      noSearch: false,
    };
  },
  methods: {
    async getListOfUsers() {
      const colRef = collection(firestore, "users");
      const q = query(colRef, orderBy("company", "asc"));
      const querySnapshot = await getDocs(q);

      this.cosList = querySnapshot.docs.map((doc) => doc.data());
      console.log(this.cosLust);
    },
    selectSearch() {
      const searchResult = this.cosList.find((item) => {
        return (
          item.company.toLowerCase().includes(this.search.toLowerCase()) ||
          item.costumerNr.includes(this.search)
        );
      });

      if (searchResult) {
        this.noSearch = false;
        this.activeToken = searchResult.costumerNr;
      } else {
        this.noSearch = true;
      }
    },
    clearSearch() {
      this.search = "";
      this.noSearch = false;
    },
    async selectFirstCos() {
      let select = await document.getElementById("costumer-select");
      if (select && this.cosList[0]) {
        select.value = this.cosList[0].costumerNr;
        this.$emit("load-costumer", this.cosList[0].costumerNr);
      }
    },
  },
  watch: {
    activeToken(value) {
      this.$emit("load-costumer", value);
    },
    cosList(list) {
      if (
        list.length >> 0 &&
        document.getElementById("search-button").parentElement
          .nextElementSibling === null
      )
        this.selectFirstCos();
    },
  },
  mounted() {
    this.getListOfUsers();
  },
};
</script>

<style scoped>
input,
select {
  width: calc(50% - 12.5px);
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0.375rem 0.75rem;
  padding-left: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
input {
  width: calc(50% - 83.5px);
}
#ze-other-arrow {
  position: absolute;
  left: calc(48% - 13px);
  top: 11px;
  color: #9f9f9f;
}
#little-frame {
  position: relative;
  display: flex;
  justify-content: space-between;
}
#magnifying-glass {
  position: absolute;
  right: 94px;
  top: 10px;
  color: #9f9f9f;
}
#delete-search {
  position: absolute;
  right: 120px;
  top: 10px;
  color: #9f9f9f;
}
#delete-search:hover {
  color: #c7c7c7;
  cursor: pointer;
}
#search-button {
  padding: 5px 10px;
  border: 1px solid rgb(64, 137, 201);
  background-color: rgb(64, 137, 201);
  color: white;
  border-radius: 5px;
  font-size: 14px;
}
#search-button:hover {
  cursor: pointer;
  background-color: #2d6ba1;
  border-color: #2d6ba1;
}
#no-search-info {
  position: absolute;
  top: -30px;
  left: 50%;
  color: red;
}
</style>
<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div>
        <label> Projektname </label>
        <input
          id="input-projectname"
          v-model="form.projectname"
          ref="projectname"
          type="text"
          placeholder="Projektname"
          autocomplete="false"
          required
        />
      </div>

      <div>
        <label>Status </label>
        <select v-model="form.status" required ref="status">
          <option selected disabled>Status</option>
          <option
            v-for="option in options"
            :value="option.value"
            :key="option.id"
          >
            {{ option.text }}
          </option>
        </select>
        <fa-icon
          class="stat-circle"
          style="color: #ffd500"
          v-if="form.status === 'inProgress'"
          :icon="['fas', 'circle']"
        ></fa-icon>
        <fa-icon
          class="stat-circle"
          style="color: #00e600"
          v-if="form.status === 'done'"
          :icon="['fas', 'circle']"
        ></fa-icon>
        <fa-icon
          class="stat-circle"
          style="color: #ff0000"
          v-if="form.status === 'canceled'"
          :icon="['fas', 'circle']"
        ></fa-icon>
        <fa-icon id="arrow" :icon="['fas', 'caret-down']"></fa-icon>
      </div>

      <div>
        <label for="datepicker"> Projektende </label>
        <date-picker
          placeholder="01.01.2001"
          class="datePicker"
          ref="date"
          v-model="form.date"
          valueType="format"
          time-title-format="DD.MM.YYYY"
          title-format="DD.MM.YYYY"
          format="DD.MM.YYYY"
          required
        ></date-picker>
      </div>

      <div>
        <label for="date2picker"> Projektende (ComLine)</label>
        <date-picker
          placeholder="01.01.2001"
          id="date2Picker"
          class="datePicker"
          ref="date2"
          v-model="form.dateCom"
          valueType="format"
          time-title-format="DD.MM.YYYY"
          title-format="DD.MM.YYYY"
          format="DD.MM.YYYY"
          required
        ></date-picker>
      </div>

      <div>
        <label> Ansprechpartner </label>
        <span v-for="(elements, index) in form.mail" :key="index + count">
          <input
            :id="'mailInput' + index"
            v-model="form.mail[index]"
            @click="removeColor('mailInput' + index)"
            ref="partnerMail"
            type="text"
            placeholder="Email des Ansprechpartners"
            autocomplete="false"
            class="mail-input"
            required
          />
          <span
            class="remove"
            v-if="index !== 0"
            @click="() => {form.mail.splice(index, 1); count++; removeColor('mailInput' + index)}"
            >&#10005;</span
          >
          <p class="error-message" v-if="displayError === 'mailInput' + index">Bitte geben SIe eine gültige E-Mail-Adresse an.</p>
          </span
        >
        <button type="button" id="mail-button" @click="() => {form.mail.push(''); count++;}">
          weitererer Ansperechpartner
        </button>
      </div>

      <div>
        <label> Beschreibung </label>
        <div id="editor-bucket">
          <Editor
            api-key="lspodsi6g4j8d39md471b78kif3sej01zbno3rcqui5oknyo"
            :init="{
              menubar: false,
              statusbar: false,
              fontsize_formats: '11pt 12pt 14pt 18pt 24pt',
              language: 'de',
              toolbar:
                'undo redo | bold italic | bullist numlist | outdent indent | fontsizeselect | backcolor',
              setup(editor) {
                editor.ui.registry.addButton('myFullscreen', {
                  text: 'Vollbild',
                  onAction() {
                    setEditorToFullscreen();
                  },
                });
                editor.on('init', (e) => {
                  loadEditorStyle();
                });
              },
            }"
            v-model="form.description"
          ></Editor>
        </div>
        <div>
          <label> Quartals Ziele </label>
          <div id="editor-bucket">
            <Editor
              api-key="lspodsi6g4j8d39md471b78kif3sej01zbno3rcqui5oknyo"
              :init="{
                menubar: false,
                statusbar: false,
                fontsize_formats: '11pt 12pt 14pt 18pt 24pt',
                language: 'de',
                toolbar:
                  'undo redo | bold italic | bullist numlist | outdent indent | fontsizeselect',
                setup(editor) {
                  editor.ui.registry.addButton('myFullscreen', {
                    text: 'Vollbild',
                    onAction() {
                      setEditorToFullscreen();
                    },
                  });
                  editor.on('init', (e) => {
                    loadEditorStyle();
                  });
                },
              }"
              v-model="form.goalnote"
            ></Editor>
          </div>
        </div>
      </div>

      <div>
        <label for="file-input">Anhänge</label>
        <input type="file" id="file-input" @change="loadFiles" multiple />
      </div>

      <div id="file-list">
        <ul>
          <li v-for="(element, index) in files" :key="index">
            {{ element.name }}
            <button
              type="button"
              class="delete-file"
              @click="removeFile(element)"
            >
              <fa-icon :icon="['fas', 'times']"></fa-icon>
            </button>
          </li>
          <li v-for="(element, index) in appendix" :key="index">
            {{ element.displayName }}
            <button
              type="button"
              class="delete-file"
              @click="deleteFile(element)"
            >
              <fa-icon :icon="['fas', 'times']"></fa-icon>
            </button>
          </li>
        </ul>
      </div>

      <div class="errormessage" v-if="errormessage">{{ errormessage }}</div>

      <button type="submit" v-if="!working && !done">Speichern</button>
      <button type="button" v-if="docRef" @click="deleteProject">
        Löschen
      </button>
      <button type="button" v-if="working" disabled class="working">
        <Spinner />
      </button>
      <button type="button red" v-if="done" disabled class="done">
        <fa-icon class="rotating" :icon="['fas', 'check']"></fa-icon>
      </button>
    </form>

    <!--  <div class="overlay">
      
      <div class="customModal" v-if="done">
        <div style="padding: 0 10px">Ihr Feedback ist wurde gespeichert.</div>
        <button @click="done = false" style="margin: 10px">OK</button>
      </div>
    </div> -->
    <div class="customModal" v-if="working || done" @load="reloadNavi">
      <Spinner v-if="working" style="font-size: 50px; height: 50px" />
      <div v-if="done" style="padding: 0 10px">
        <span v-if="feedback">Ihr Feedback wurde gespeichert.</span>
        <span v-else>Ihr Feedback wurde gelöscht.</span>
      </div>
      <button v-if="done" id="x-done" @click="done = false" class="close">
        <fa-icon :icon="['fas', 'times']"></fa-icon>
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/de";
import Spinner from "../assets/Spinner.vue";
import Editor from "@tinymce/tinymce-vue";

import { firestore, storage, auth } from "../plugins/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, listAll, deleteObject } from "firebase/storage";

export default {
  name: "ApplyView",
  components: {
    DatePicker,
    Spinner,
    Editor: Editor,
  },
  props: {
    projectToEdit: Object,
    token: String,
  },
  data() {
    return {
      count: 0,
      displayError: "",
      done: false,
      doneCom: false,
      working: false,
      errormessage: "",
      feedback: true,
      docRef: null,
      files: [],
      appendix: [],
      options: [
        { id: 1, text: "In Arbeit", value: "inProgress" },
        { id: 2, text: "Abgeschlossen", value: "done" },
        { id: 3, text: "Abgebrochen", value: "canceled" },
      ],
      form: {
        date: null,
        dateCom: null,
        projectname: "",
        status: null,
        description: null,
        goalnote: "",
        appendix: false,
        mail: [""],
        lastUser: auth.currentUser.email,
      },
    };
  },
  watch: {
    done: function (val) {
      if (val) {
        this.resetForm();
        setTimeout(() => {
          document.getElementsByClassName("customModal")[0].style.opacity = "0";
        }, 1000);
        setTimeout(() => {
          if (this.done === true) this.done = false;
        }, 6000);
      } else this.reloadNavi();
    },
    files: function (val) {
      if (val.length > 0 || this.appendix.length > 0) {
        this.form.appendix = true;
      } else if (val.length === 0 && this.appendix.length === 0) {
        this.form.appendix = false;
      } else {
        //no change neccessary
      }
    },
  },
  methods: {
    onSubmit() {
      if (this.checkMailInput()) this.saveInFirestore();
    },
    saveInFirestore() {
      this.working = true;
      this.done = false;

      if (this.docRef === null) {
        addDoc(collection(firestore, this.token), this.form)
          .then((doc) => {
            if (this.files.length !== 0) this.uploadFiles(doc.id);
            this.working = false;
            this.done = true;
          })
          .catch(() => {
            this.working = false;
            this.done = false;
          });
      } else {
        //bestehenden Datensatz updaten
        const docRef = doc(firestore, this.token, this.docRef);
        updateDoc(docRef, this.form)
          .then(() => {
            if (this.files.length !== 0) this.uploadFiles(this.docRef);
            this.working = false;
            this.done = true;
          })
          .catch(() => {
            this.working = false;
            this.done = false;
          });
      }
      this.$emit("reload-navi");
    },
    loadFiles() {
      //add documents from input to array
      const files = document.getElementById("file-input").files;
      this.files = [];
      for (let i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }
      document.getElementById("file-input").value = "";
    },
    removeFile(file) {
      const index = this.files.indexOf(file);
      this.files.splice(index, 1);
    },
    uploadFiles(id) {
      this.files.forEach((file) => {
        //rename document
        let renamedFile = this.renameFile(file, id);
        const fileRef = ref(storage, this.token + "/" + renamedFile.name);
        //upload to storage
        uploadBytes(fileRef, renamedFile)
          .then(() => {})
          .catch((err) => {
            alert("Beim Hochladen der Anhänge ist ein Fehler aufgetreten", err);
            console.log("firebase storage error", err);
          });
      });
    },
    renameFile(file, id) {
      const typeEnd = file.name.split(".")[file.name.split(".").length - 1];
      const name = file.name.slice(0, file.name.length - typeEnd.length - 1);

      var blob = file.slice(0, file.size, file.type);
      let newFile = new File([blob], name + "-" + id + "." + typeEnd, {
        type: file.type,
      });
      return newFile;
    },
    loadAppendix() {
      const stoRef = ref(storage, this.token);
      listAll(stoRef)
        .then((res) => {
          res.items.forEach((file) => {
            if (file.name.includes(this.projectToEdit["key"])) {
              this.renameAndPush(file);
            }
          });
        })
        .catch((err) => {
          console.log("firebase storage error", err);
        });
    },
    deleteFile(file) {
      const stoRef = ref(storage, this.token + "/" + file.name);
      // Delete the file
      deleteObject(stoRef)
        .then(() => {
          // File deleted successfully
          const index = this.appendix.indexOf(file);
          this.appendix.splice(index, 1);
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log("firebase storage error", error);
        });
    },
    renameAndPush(file) {
      const name = file.name.replaceAll("-" + this.projectToEdit["key"], "");
      this.appendix.push({
        displayName: name,
        name: file.name,
      });
    },
    deleteProject() {
      const docRef = doc(firestore, this.token, this.docRef);
      this.files.forEach((file) => {
        this.deleteFile(file);
      });
      deleteDoc(docRef)
        .then(() => {
          this.feedback = false;
          this.working = false;
          this.done = true;
        })
        .catch(() => {
          this.working = false;
          this.done = false;
        });
    },
    correctMailArray() {
      if (!this.form.mail) {
        this.form.mail = [""];
      } else if (typeof this.form.mail !== "object") {
        this.form.mail = [this.form.mail];
      } else {
        return;
      }
    },
    checkMailInput() {
      let mails = this.form.mail;
      for (let i = 0; i < mails.length; i++) {
        if (!mails[i].includes("@") || !mails[i].includes(".")) {
          let errorElement = document.getElementById("mailInput" + i);
          let y = errorElement.getBoundingClientRect().top;
          errorElement.style.borderColor = "red";
          this.displayError = "mailInput" + i;
          document.getElementById("mainView").scrollTo(0, y);
          return false;
        }
      }
      return true;
    },
    removeColor(element) {
      let target = document.getElementById(element);
      target.style.borderColor = "#ccc";
      this.displayError = ";"
    },
    loadEditorStyle() {
      //TinyMCE Editor gewünschten style hinterherwerfen
      let editor = document.getElementsByClassName("tox-tinymce")[0];
      if (editor) editor.style.borderRadius = "5px";
      editor = document.getElementsByClassName("tox-tinymce")[1];
      if (editor) editor.style.borderRadius = "5px";
    },
    resetForm() {
      this.docRef = null;
      this.form.date = null;
      this.form.dateCom = null;
      this.form.projectname = "";
      this.form.status = null;
      this.form.description = "";
      this.form.appendix = false;
      this.form.goalnote = "";
      this.files = [];
      this.appendix = [];
      this.form.mail = [""];
      this.form.lastUser = auth.currentUser.email;
    },
    reloadNavi() {
      this.$emit("reload-navi");
    },
  },
  mounted() {
    if (this.projectToEdit !== null && this.projectToEdit["doc"].appendix) {
      this.form = this.projectToEdit["doc"];
      this.correctMailArray();
      this.docRef = this.projectToEdit["key"];
      this.loadAppendix();
      this.form.lastUser = auth.currentUser.email;
    } else if (
      this.projectToEdit !== null &&
      !this.projectToEdit["doc"].appendix
    ) {
      this.form = this.projectToEdit["doc"];
      this.correctMailArray();
      this.docRef = this.projectToEdit["key"];
      this.form.lastUser = auth.currentUser.email;
    } else {
      this.resetForm();
    }
  },
};
</script>

<style scoped>
input,
select {
  width: 100%;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0.375rem 0.75rem;
  padding-left: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
}
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: block;
  margin-bottom: 5px;
}
button {
  margin-top: 20px;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid rgb(64, 137, 201);
  background-color: rgb(64, 137, 201);
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
button:hover {
  background-color: #2d6ba1;
  border-color: #2d6ba1;
}
.red {
  background-color: #cb444a !important;
  border-color: #cb444a !important;
}
div {
  margin-top: 15px;
  max-width: 100%;
  position: relative;
}
.datePicker {
  width: 100%;
  margin-top: 0;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.working {
  background-color: lightgray;
  border: none;
  padding: 6px 35px;
  cursor: default;
}
.done {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 6px 35px;
  cursor: default;
}
.error {
  border-color: red !important;
}
.errormessage {
  color: red;
}
.cashback {
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.cashbackReady {
  border: 1px solid #bee5eb;
  border-radius: 5px;
  color: #0c5460;
  background-color: #d1ecf1;
}
.warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}
.customModal {
  position: fixed;
  top: 100px;
  left: 0;
  background-color: #e8f1f9;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100vw;
  max-width: 100vw;
  box-shadow: #9e9e9e 0.25px 1px 2px;
  transition: opacity 5s;
}
.customModal div,
.close {
  margin: 15px;
}
.close {
  background-color: #e8f1f9;
  border: none;
  color: #5a6268;
  cursor: pointer;
  position: absolute;
  margin-left: 350px;
}
.close:hover {
  color: rgb(187, 187, 187);
}
#arrow {
  position: absolute;
  right: 10px;
  top: 34px;
  color: #9f9f9f;
}
.stat-circle {
  position: absolute;
  left: 10px;
  top: 36px;
  height: 14px;
}
select:nth-of-type(1) {
  padding-left: 32px;
}
.content {
  margin-bottom: 500px;
}
#file-list li {
  margin-top: 15px;
}
.delete-file {
  margin: 0;
  position: absolute;
  right: 15px;
}
.mail-input {
  margin-bottom: 15px;
}
.remove {
  background-color: transparent;
  color: #9f9f9f;
  border: none;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  position: absolute;
  right: 9px;
  margin-top: 6px;
}
.remove:hover {
  cursor: default;
}
#mail-button {
  margin-top: 5px;
}
.error-message {
  color: red;
}
</style>

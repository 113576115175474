import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {getDatabase, connectDatabaseEmulator} from "firebase/database";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCfQOx9tGWertPN192V1ycJMu-cOFgKT3c",
  authDomain: "clgmbh-adobe-kickback.firebaseapp.com",
  databaseURL: "https://clgmbh-adobe-kickback-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "clgmbh-adobe-kickback",
  storageBucket: "clgmbh-adobe-kickback.appspot.com",
  messagingSenderId: "111929902121",
  appId: "1:111929902121:web:e7a1f1ea381bf2d278c838"
};

const region = 'europe-west3';

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getDatabase(firebase);
const firestore = getFirestore(firebase)
const functions = getFunctions(firebase, region);
const storage = getStorage(firebase);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectDatabaseEmulator(db, 'localhost', 9000);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFirestoreEmulator(firestore, "localhost", 8080);
}

export {firebase, auth, functions, firestore, storage, db, region} 
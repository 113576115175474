<template>
  <div style="margin-top: 85px;" id="app">
    <navbar style="position: fixed;top: 0;z-index: 1000;width: 100%;" v-if="this.user.loggedIn"></navbar>
      <router-view></router-view>
  </div>
</template>
<script>
import navbar from "./components/Navbar";
import { mapGetters } from "vuex";

export default {
  components: {
    navbar
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style>
.card .table {
  margin-bottom: 0rem !important;
  }
  body {
    overflow: hidden;
  }
</style>
import Vue from "vue";
import Vuex from "vuex";
import { db } from './plugins/firebase';
import { ref, onValue, query} from "firebase/database"

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    data: null
  },
  getters: {
    user(state){
      return state.user
    },
    data(state){
      return state.data
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_DATA(state, data) {
      state.data = data;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
       
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          admin: user.admin
        });
      } else {
        commit("SET_USER", null);
      }
    },
    fetchData( {commit}) {
      onValue(query(ref(db, `applys`)), snapshot => {
        const data = snapshot.val();
        commit("SET_DATA", data);
      })
    }
  }
});
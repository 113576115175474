import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from "./routes";

// Font Awesome
import "./plugins/fontawesome";

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import "@comlinedev/bootstrap4/dist/css/bootstrap.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVueIcons } from 'bootstrap-vue'

import { auth } from "./plugins/firebase"
import { onAuthStateChanged } from "firebase/auth"

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

onAuthStateChanged(auth , async (user) => {
  if(user){
    var idTokenResult = await user.getIdTokenResult()
    var isAdmin = idTokenResult.claims.admin
    if (isAdmin) {
      await store.dispatch("fetchData")
      user.admin = 'admin'
    }
  }
  await store.dispatch("fetchUser", user)
  new Vue({
    render: h => h(App),
    router,
    store,
  }).$mount('#app')
})

import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faPlus,
  faCircle,
  faEdit,
  faEye,
  faSpinnerThird,
  faTimes,
  faCheck,
  faSackDollar,
  faFileAlt,
  faCheckDouble,
  faHourglassHalf,
  faEuroSign,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faSignOut,
  faEnvelope,
  faKey,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faTrash,
  faPlus,
  faCircle,
  faEdit,
  faEye,
  faSpinnerThird,
  faTimes,
  faCheck,
  faSackDollar,
  faFileAlt,
  faCheckDouble,
  faHourglassHalf,
  faEuroSign,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faSignOut,
  faEnvelope,
  faKey,
  faSearch,
);

Vue.component("fa-icon", FontAwesomeIcon);
